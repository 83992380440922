import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./../App.css";

// import useWindowPosition from '../hook/useWindowPosition';
const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: '20vh',
    // maxWidth: '100vw',
    // height: '70px',
    // width: '100%',
    // display: 'flex',
    // position: 'fixed',
    // flexWrap: 'wrap',
    // flexDirection: 'row',
    // justifyContent: 'center',
    // alignItems: 'center',
    // bottom: '0',
    // flexBasis: '33%',
    // backgroundColor: 'rgb(136, 136, 136)', // light gray
    // backgroundColor: 'rgb(35, 35, 35)', // dark gray
    // [theme.breakpoints.down('md')]: {
    //   flexDirection: 'column',
    // },
  },
  logo: {
    width: "180px",
    height: "70px",
    padding: "20px",
    opacity: "0.85",
    [theme.breakpoints.down("md")]: {
      width: "120px",
      height: "50px",
      padding: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100px",
      height: "40px",
      padding: "7px",
    },
    // margin: '20px',
    // backgroundColor: 'rgb(70, 130, 120)',
  },
}));

export default function Projects() {
  const classes = useStyles();
  return (
    <div class="footer" id="footer">
      <div className={classes.main}>
        <a href="https://www.linkedin.com/in/badiaa" rel="noreferrer" target="_blank">
          <img className={classes.logo} src="/images/linkedin-logo.png" alt="linkedin"></img>
        </a>
        <a href="/assets/resu-me.pdf" rel="noreferrer" target="_blank">
          <img className={classes.logo} src="/images/resume-logo.png" alt="resume"></img>
        </a>
        <a href="https://github.com/deep-blue-sea-7?tab=repositories" rel="noreferrer" target="_blank">
          <img className={classes.logo} src="/images/github-logo.png" alt="github"></img>
        </a>
      </div>
    </div>
  );
}
